import * as React from 'react';
import { navigate } from 'gatsby';
import { PAGE } from '../configs';
import { Button, Container, Layout } from '../ui';

const NotFoundPage = () => (
	<Layout title="Seite nicht gefunden - Malerei Dori Art">
		<Container>
			<h1>404: Not found</h1>
			<p>Seite nicht gefunden</p>
			<p>
				<Button
					variant="primary"
					onClick={() => {
						navigate(PAGE.home);
					}}
				>
					Zur Startseite
				</Button>
			</p>
		</Container>
	</Layout>
);

export default NotFoundPage;
